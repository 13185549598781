import { Search } from 'lucide-react'
import { useEffect, useState } from 'react'

import { Footer } from '@/components/Footer'
import { Header } from '@/components/Header'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { UserCard } from '@/components/user/UserCard'
import { CreateUserDialog } from '@/components/user/UserCreateDialog'

interface User {
  id: number
  username: string
  email: string
  collaborator: 'BROKER' | 'ADMIN'
}

export function UserControl() {
  const [users, setUsers] = useState<User[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filterType, setFilterType] = useState('name')
  const [roleFilter, setRoleFilter] = useState('all')
  const route = import.meta.env.VITE_URL_ENDPOINT
  const fetchUsers = async () => {
    try {
      const response = await fetch(`${route}/api/users`)
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      const formattedUsers = data.map((user: User) => ({
        id: user.id,
        username: user.username,
        email: user.email,
        collaborator: user.collaborator,
      }))
      setUsers(formattedUsers)
    } catch (error) {
      console.error('Failed to fetch users:', error)
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const filteredUsers = users.filter((user) => {
    const matchesSearch =
      (filterType === 'name' &&
        user.username.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (filterType === 'email' &&
        user.email.toLowerCase().includes(searchTerm.toLowerCase()))
    const matchesRole = roleFilter === 'all' || user.collaborator === roleFilter
    return matchesSearch && matchesRole
  })

  const headerContent = (
    <div className="w-full max-w-4xl">
      <h1 className="-mt-5 mb-5 text-center text-2xl font-bold">
        Cadastro de Usuários
      </h1>
      <div className="flex flex-col space-y-4">
        <div className="flex space-x-4">
          <div className="relative flex-grow">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <Search className="text-gray-400" />
            </span>
            <Input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10"
              placeholder={`Buscar por ${filterType === 'name' ? 'nome' : 'email'}`}
            />
          </div>
          <Select value={filterType} onValueChange={setFilterType}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Filtrar por" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="name">Nome</SelectItem>
              <SelectItem value="email">Email</SelectItem>
            </SelectContent>
          </Select>
          <Select value={roleFilter} onValueChange={setRoleFilter}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Filtrar por cargo" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Todos</SelectItem>
              <SelectItem value="ADMIN">Admin</SelectItem>
              <SelectItem value="BROKER">Broker</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <CreateUserDialog onUserCreate={fetchUsers} />
      </div>
    </div>
  )

  return (
    <div className="min-h-screen">
      <Header filterContent={headerContent} />

      <div className="p-8">
        <div className="mx-auto max-w-4xl">
          <h1 className="mb-3 text-center text-2xl font-bold">
            Lista de Usuários
          </h1>
          <div className="mt-4">
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <UserCard
                  key={user.id}
                  id={user.id}
                  name={user.username}
                  email={user.email}
                  role={user.collaborator}
                  onUserUpdate={fetchUsers}
                />
              ))
            ) : (
              <h2 className="mt-10">
                Não possui usuário cadastrado no sistema
              </h2>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
