import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Footer } from '@/components/Footer'
import { Address } from '@/components/form-property/address'
import { Leisure } from '@/components/form-property/leisure'
import type {
  LeisureData,
  PropertyFormData,
  PropertyPayload,
} from '@/components/form-property/property-types'
import { PropertyOptions } from '@/components/form-property/PropertyOptions'
import { PropertyTypeForm } from '@/components/form-property/PropertyTypeForm'
import { Button } from '@/components/ui/button'
import { Toaster } from '@/components/ui/toaster'
import { useToast } from '@/components/ui/use-toast'

const defaultLeisure: LeisureData = {
  id: 0,
  partyHall: false,
  adultPool: false,
  gameRoom: false,
  kidsPool: false,
  gym: false,
  playground: false,
  sportsCourt: false,
  sauna: false,
  tennisCourt: false,
  bbqArea: false,
  squashCourt: false,
  socialHall: false,
  wetDeckPool: false,
  lapPool: false,
  kidsBeachPool: false,
  solarium: false,
  poolBar: false,
  massageRoom: false,
  womenSpace: false,
  petPlace: false,
  youthGameRoom: false,
  toyLibrary: false,
  pilatesSpace: false,
  lounge: false,
  gourmetSpace: false,
  kidsPartyRoom: false,
  adultPartyRoomWithBar: false,
  adultGameRoom: false,
  readingRoom: false,
  hammockArea: false,
  zenSpace: false,
  coworkingSpace: false,
  adultTVRoom: false,
  gourmetBar: false,
  cinemaRoom: false,
  property: { data: null },
}

export function PropertyEditControl() {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { toast } = useToast()

  const [formData, setFormData] = useState<PropertyFormData>({
    id: 0,
    region: '',
    street: '',
    neighborhood: '',
    number: '',
    apartmentNumber: '',
    zipCode: '',
    totalArea: '',
    usableArea: '',
    commonArea: '',
    privateArea: '',
    status: '',
    condominium: null,
    internalObservation: '',
    externalObservation: '',
    distanceFromBeach: '',
    price: '',
    condominiumFee: '',
    barter: false,
    financing: false,
    paymentMethod: '',
    iptu: '',
    seaView: false,
    owner: null,
    block: null,
    propertyType: '',
    photos: { connect: [] },
    leisure: defaultLeisure,
    condominiumData: undefined,
    apartment: undefined,
    house: undefined,
    commercial_property: undefined,
    area: undefined,
  })

  const [loading, setLoading] = useState(false)

  const [ownerName, setOwnerName] = useState('')
  const [condominiumName, setCondominiumName] = useState<string | undefined>(
    undefined,
  )

  const route = import.meta.env.VITE_URL_ENDPOINT

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await fetch(
          `${route}/api/properties/${id}?populate=leisure,owner,condominium`,
        )
        const result = await response.json()

        if (!response.ok) {
          throw new Error('Erro ao buscar a propriedade')
        }

        const prop = result.data.attributes
        const leisureData = prop.leisure?.data

        // Aqui assumimos que o Owner tem um campo "Name".
        const fetchedOwnerName = prop.owner.data.attributes.name
        const fetchedCondominiumName = prop.condominium?.data?.attributes?.Name
        const lastEditor = localStorage.getItem('userName')

        const updatedFormData: PropertyFormData = {
          id: Number(id),
          region: prop.region || '',
          street: prop.street || '',
          neighborhood: prop.neighborhood || '',
          number: prop.number || '',
          apartmentNumber: prop.apartmentNumber || '',
          zipCode: prop.zipCode || '',
          totalArea: prop.totalArea || '',
          usableArea: prop.usableArea || '',
          commonArea: prop.commonArea || '',
          privateArea: prop.privateArea || '',
          status: prop.status || '',
          internalObservation: prop.internalObservation || '',
          externalObservation: prop.externalObservation || '',
          distanceFromBeach: prop.distanceFromBeach || '',
          price: prop.price || '',
          condominiumFee: prop.condominiumFee || '',
          barter: prop.barter || false,
          financing: prop.financing || false,
          paymentMethod: prop.paymentMethod || '',
          iptu: prop.iptu || '',
          seaView: prop.seaView || false,
          owner: prop.owner?.data?.id || null,
          block: prop.block ?? null,
          condominium: prop.condominium?.data?.id || null,
          propertyType: prop.propertyType || '',
          photos: { connect: [] },
          leisure: {
            ...defaultLeisure,
            ...(leisureData?.attributes || {}),
            id: leisureData?.id || 0,
            property: { data: null },
          },
          lastEditor: lastEditor || '',
          condominiumData: prop.condominium?.data || undefined,
          apartment:
            prop.propertyType === 'apartment'
              ? {
                  type: prop.type || '',
                  numberOfBedrooms: prop.numberOfBedrooms || '',
                  numberOfSuites: prop.numberOfSuites || '',
                  numberOfRooms: prop.numberOfRooms || '',
                  socialBathroom: prop.socialBathroom || '',
                  halfBath: prop.halfBath || '',
                  kitchen: prop.kitchen || '',
                  apartmentNumber: prop.apartmentNumber || '',
                  garage: prop.garage || '',
                  intercom: prop.intercom || false,
                  maidsQuarters: prop.maidsQuarters || false,
                  laundryArea: prop.laundryArea || false,
                  elevator: prop.elevator || false,
                  furnished: prop.furnished || false,
                  furnitureNotes: prop.furnitureNotes || '',
                }
              : undefined,
          house:
            prop.propertyType === 'house'
              ? {
                  type: prop.type || '',
                  numberOfBedrooms: prop.numberOfBedrooms || '',
                  numberOfSuites: prop.numberOfSuites || '',
                  livingRoom: prop.livingRoom || '',
                  socialBathroom: prop.socialBathroom || '',
                  halfBath: prop.halfBath || '',
                  kitchen: prop.kitchen || '',
                  laundryRoom: prop.laundryRoom || '',
                  pantry: prop.pantry || '',
                  closet: prop.closet || '',
                  garage: prop.garage || '',
                  intercom: prop.intercom || false,
                }
              : undefined,
          commercial_property:
            prop.propertyType === 'commercial_property'
              ? {
                  type: prop.type || '',
                  cabinets: prop.cabinets || '',
                  bathrooms: prop.bathrooms || '',
                  conservation: prop.conservation || '',
                  guardhouse: prop.guardhouse || '',
                  numberOfRooms: prop.numberOfRooms || '',
                  kitchen: prop.kitchen || '',
                  copa: prop.copa || '',
                  keys: prop.keys || '',
                  intercom: prop.intercom || false,
                }
              : undefined,
          area:
            prop.propertyType === 'area'
              ? {
                  classification: prop.classification || '',
                  type: prop.type || '',
                  front: prop.front || '',
                  back: prop.back || '',
                  rightSide: prop.rightSide || '',
                  leftSide: prop.leftSide || '',
                  corner: prop.corner || false,
                  leveled: prop.leveled || false,
                  fenced: prop.fenced || false,
                  waterSupply: prop.waterSupply || false,
                  electricitySupply: prop.electricitySupply || false,
                }
              : undefined,
        }

        setFormData(updatedFormData)
        setOwnerName(fetchedOwnerName)
        setCondominiumName(fetchedCondominiumName)
      } catch (error) {
        console.error('Erro ao buscar i Imóvel:', error)
        toast({
          title: 'Erro',
          description: 'Não foi possível carregar os dados do Imóvel.',
          variant: 'destructive',
        })
      }
    }

    if (id) {
      fetchProperty()
    }
  }, [id, route, toast])

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value, type } = e.target

    let newValue: string | number | boolean | null = value

    if (type === 'checkbox') {
      const target = e.target as HTMLInputElement
      newValue = target.checked
    }

    // Não deixamos editar owner, block, condominium
    if (['owner', 'block', 'condominium'].includes(name)) {
      return
    }

    setFormData((prevState: PropertyFormData) => ({
      ...prevState,
      [name]: newValue,
    }))
  }

  const handleLeisureChange = (field: keyof LeisureData, value: unknown) => {
    setFormData((prev) => ({
      ...prev,
      leisure: {
        ...prev.leisure,
        [field]: value,
      },
    }))
  }

  const handleLeisureCheckboxChange = (
    name: keyof PropertyFormData['leisure'],
    checked: boolean | 'indeterminate',
  ) => {
    setFormData((prevState: PropertyFormData) => ({
      ...prevState,
      leisure: {
        ...prevState.leisure,
        [name]: checked === true,
      },
    }))
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const lastEditor = localStorage.getItem('userName')

      const propertyPayload: PropertyPayload = {
        data: {
          region: formData.region,
          street: formData.street,
          neighborhood: formData.neighborhood,
          number: formData.number,
          zipCode: formData.zipCode,
          totalArea: formData.totalArea,
          usableArea: formData.usableArea,
          commonArea: formData.commonArea,
          privateArea: formData.privateArea,
          status: formData.status,
          internalObservation: formData.internalObservation,
          externalObservation: formData.externalObservation,
          distanceFromBeach: formData.distanceFromBeach,
          price: formData.price,
          condominiumFee: formData.condominiumFee,
          barter: formData.barter,
          financing: formData.financing,
          paymentMethod: formData.paymentMethod,
          iptu: formData.iptu,
          seaView: formData.seaView,
          owner: formData.owner || null,
          block: formData.block || null,
          condominium: formData.condominium || null,
          propertyType: formData.propertyType,
          leisure:
            formData.leisure.id && formData.leisure.id > 0
              ? formData.leisure.id
              : null,
          ...(formData.propertyType === 'apartment' && formData.apartment
            ? { ...formData.apartment }
            : {}),
          ...(formData.propertyType === 'house' && formData.house
            ? { ...formData.house }
            : {}),
          ...(formData.propertyType === 'commercial_property' &&
          formData.commercial_property
            ? { ...formData.commercial_property }
            : {}),
          ...(formData.propertyType === 'area' && formData.area
            ? { ...formData.area }
            : {}),
          lastEditor: lastEditor || '',
        },
      }

      const propertyResponse = await fetch(`${route}/api/properties/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(propertyPayload),
      })

      const propertyResult = await propertyResponse.json()
      if (!propertyResponse.ok) {
        console.error('Erro ao editar a propriedade:', propertyResult)
        throw new Error('Erro ao editar a propriedade.')
      }

      // Atualiza lazer, se existir
      if (formData.leisure.id && formData.leisure.id > 0) {
        const leisurePayload = {
          data: {
            partyHall: formData.leisure.partyHall,
            adultPool: formData.leisure.adultPool,
            gameRoom: formData.leisure.gameRoom,
            kidsPool: formData.leisure.kidsPool,
            gym: formData.leisure.gym,
            playground: formData.leisure.playground,
            sportsCourt: formData.leisure.sportsCourt,
            sauna: formData.leisure.sauna,
            tennisCourt: formData.leisure.tennisCourt,
            bbqArea: formData.leisure.bbqArea,
            squashCourt: formData.leisure.squashCourt,
            socialHall: formData.leisure.socialHall,
            wetDeckPool: formData.leisure.wetDeckPool,
            lapPool: formData.leisure.lapPool,
            kidsBeachPool: formData.leisure.kidsBeachPool,
            solarium: formData.leisure.solarium,
            poolBar: formData.leisure.poolBar,
            massageRoom: formData.leisure.massageRoom,
            womenSpace: formData.leisure.womenSpace,
            petPlace: formData.leisure.petPlace,
            youthGameRoom: formData.leisure.youthGameRoom,
            toyLibrary: formData.leisure.toyLibrary,
            pilatesSpace: formData.leisure.pilatesSpace,
            lounge: formData.leisure.lounge,
            gourmetSpace: formData.leisure.gourmetSpace,
            kidsPartyRoom: formData.leisure.kidsPartyRoom,
            adultPartyRoomWithBar: formData.leisure.adultPartyRoomWithBar,
            adultGameRoom: formData.leisure.adultGameRoom,
            readingRoom: formData.leisure.readingRoom,
            hammockArea: formData.leisure.hammockArea,
            zenSpace: formData.leisure.zenSpace,
            coworkingSpace: formData.leisure.coworkingSpace,
            adultTVRoom: formData.leisure.adultTVRoom,
            gourmetBar: formData.leisure.gourmetBar,
            cinemaRoom: formData.leisure.cinemaRoom,
          },
        }

        const leisureResponse = await fetch(
          `${route}/api/leisures/${formData.leisure.id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(leisurePayload),
          },
        )

        const leisureResult = await leisureResponse.json()
        if (!leisureResponse.ok) {
          console.error('Erro ao editar o lazer:', leisureResult)
          throw new Error('Erro ao editar o lazer.')
        }
      }

      toast({
        title: 'Sucesso',
        description: 'Imóvel e lazer editados com sucesso!',
      })
      setTimeout(() => {
        navigate(`/propriedade/${id}`)
      }, 2000)
    } catch (error) {
      console.error('Erro ao enviar os dados de edição:', error)
      toast({
        title: 'Erro',
        description: 'Erro ao editar o imóvel. Por favor, tente novamente.',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex h-full w-full flex-col bg-gray-100 p-8">
      <h1 className="mb-6 text-center text-3xl font-bold text-gray-800">
        Edição de Imóvel
      </h1>
      <div className="rounded-lg bg-white p-6 shadow-md">
        {/* Exibição somente leitura do nome do dono e do condomínio */}
        {ownerName && (
          <div className="mb-4">
            <label className="block font-semibold text-gray-700">
              Proprietário:
            </label>
            <input
              type="text"
              value={ownerName}
              disabled
              className="mt-1 w-full rounded border-gray-300 bg-gray-100 p-2"
            />
          </div>
        )}

        {condominiumName && (
          <div className="mb-4">
            <label className="block font-semibold text-gray-700">
              Condomínio:
            </label>
            <input
              type="text"
              value={condominiumName}
              disabled
              className="mt-1 w-full rounded border-gray-300 bg-gray-100 p-2"
            />
          </div>
        )}

        <Address data={formData} onChange={handleChange} />
        <PropertyOptions formData={formData} setFormData={setFormData} />
        <PropertyTypeForm formData={formData} setFormData={setFormData} />
        <Leisure
          data={formData.leisure}
          onChange={handleLeisureChange}
          onCheckboxChange={handleLeisureCheckboxChange}
        />
        <div className="mt-6 flex justify-end">
          <Button
            onClick={handleSubmit}
            disabled={loading}
            className="bg-indigo-600 text-white hover:bg-indigo-700"
          >
            {loading ? 'Enviando...' : 'Salvar Alterações'}
          </Button>
        </div>
      </div>
      <Toaster />
      <Footer />
    </div>
  )
}
