import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import BackgroundSign from '@/assets/backgroundSign.png'
import Logo from '@/assets/logo.png'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

interface HeaderProps {
  filterContent: ReactNode
}

export function Header({ filterContent }: HeaderProps) {
  const navigate = useNavigate()
  const userCollaborator = localStorage.getItem('userCollaborator')
  const isAdmin = userCollaborator === 'ADMIN'

  return (
    <div className="relative flex w-full flex-col items-center justify-center bg-gray-50">
      {/* Fundo com altura dinâmica */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${BackgroundSign})` }}
      />
      <div className="absolute inset-0 bg-black bg-opacity-10" />

      {/* Conteúdo */}
      <div className="relative z-10 flex w-full flex-col px-4 py-4 sm:px-6 md:py-6 lg:px-8">
        {/* Topo do header (logo e menu administrador) */}
        <div className="mb-2 flex w-full items-center justify-between">
          <button onClick={() => navigate('/home')}>
            <img
              src={Logo}
              width={180}
              alt="Logo"
              style={{ filter: 'brightness(0) invert(1)' }}
            />
          </button>
          {isAdmin && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="text-sm sm:text-base">
                  Opções Administrador
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={() => navigate('/usuarios')}>
                  Cadastro de Usuários
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => navigate('/regioes')}>
                  Cadastro de Região
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => navigate('/proprietarios')}>
                  Cadastro de Proprietário
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => navigate('/cadastro-propriedade')}
                >
                  Cadastro de Imóvel
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>

        {/* Container branco para os filtros */}
        <div className="mt-4 flex w-full justify-center">
          {/* Ajuste de padding para não ficar muito grande em telas menores */}
          <div className="w-full max-w-5xl bg-white bg-opacity-80 p-4 shadow-lg sm:p-6 md:p-8 lg:p-8">
            {filterContent}
          </div>
        </div>
      </div>
    </div>
  )
}
