// PropertyControl.tsx

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CondominiumSelector } from '@/components/Condominium/ComdominiumSelector'
import { Footer } from '@/components/Footer'
import { Address } from '@/components/form-property/address'
import { Leisure } from '@/components/form-property/leisure'
import { PhotoUpload } from '@/components/form-property/PhotoUpload'
import type {
  Condominium,
  LeisureData,
  LeisurePayload,
  PropertyFormData as FormPropertyFormData,
  PropertyPayload,
} from '@/components/form-property/property-types'
import { PropertyOptions } from '@/components/form-property/PropertyOptions'
import { PropertyTypeForm } from '@/components/form-property/PropertyTypeForm'
import { OwnerSelector } from '@/components/owner/OwnerSelector'
import { Button } from '@/components/ui/button'
import { Toaster } from '@/components/ui/toaster'
import { useToast } from '@/components/ui/use-toast'

export function PropertyControl() {
  const navigate = useNavigate()
  const { toast } = useToast()

  const [formData, setFormData] = useState<FormPropertyFormData>({
    id: 0,
    region: '',
    street: '',
    neighborhood: '',
    number: '',
    apartmentNumber: '',
    zipCode: '',
    totalArea: '',
    usableArea: '',
    commonArea: '',
    privateArea: '',
    status: '',
    condominium: 0,
    internalObservation: '',
    externalObservation: '',
    distanceFromBeach: '',
    price: '',
    condominiumFee: '',
    barter: false,
    financing: false,
    paymentMethod: '',
    iptu: '',
    seaView: false,
    owner: null,
    block: null,
    propertyType: '',
    photos: { connect: [] },
    leisure: {
      id: 0,
      partyHall: false,
      adultPool: false,
      gameRoom: false,
      kidsPool: false,
      gym: false,
      playground: false,
      sportsCourt: false,
      sauna: false,
      tennisCourt: false,
      bbqArea: false,
      squashCourt: false,
      socialHall: false,
      wetDeckPool: false,
      lapPool: false,
      kidsBeachPool: false,
      solarium: false,
      poolBar: false,
      massageRoom: false,
      womenSpace: false,
      petPlace: false,
      youthGameRoom: false,
      toyLibrary: false,
      pilatesSpace: false,
      lounge: false,
      gourmetSpace: false,
      kidsPartyRoom: false,
      adultPartyRoomWithBar: false,
      adultGameRoom: false,
      readingRoom: false,
      hammockArea: false,
      zenSpace: false,
      coworkingSpace: false,
      adultTVRoom: false,
      gourmetBar: false,
      cinemaRoom: false,
      property: { data: null },
      linkPhotos: '', // link de drive ou outro
      photos: { connect: [] },
    },
    linkPhotos: '',
    condominiumData: undefined,
    apartment: undefined,
    house: undefined,
    commercial_property: undefined,
    area: undefined,
    terrain: undefined,
  })

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedCondominium, setSelectedCondominium] = useState<
    Condominium | undefined
  >(undefined)

  const navigateHome = () => {
    setTimeout(() => {
      navigate('/home')
    }, 2000)
  }

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value, type } = e.target
    let newValue: string | number | boolean | null = value
    if (type === 'checkbox') {
      const target = e.target as HTMLInputElement
      newValue = target.checked
    }
    if (name === 'owner' || name === 'block') {
      newValue = Number(value)
      if (isNaN(newValue)) {
        newValue = null
      }
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }))
  }

  const handleLeisureChange = (field: keyof LeisureData, value: unknown) => {
    setFormData((prev) => ({
      ...prev,
      leisure: {
        ...prev.leisure,
        [field]: value,
      },
    }))
  }

  const handleLeisureCheckboxChange = (
    name: keyof FormPropertyFormData['leisure'],
    checked: boolean | 'indeterminate',
  ) => {
    setFormData((prev) => ({
      ...prev,
      leisure: {
        ...prev.leisure,
        [name]: checked === true,
      },
    }))
  }

  const handleSubmit = async () => {
    setLoading(true)
    const route = import.meta.env.VITE_URL_ENDPOINT

    try {
      // 1) Upload das fotos gerais (selectedFiles)
      let photoIds: number[] = []
      if (selectedFiles.length > 0) {
        const formDataPhotos = new FormData()
        selectedFiles.forEach((file) => {
          formDataPhotos.append('files', file)
        })
        const uploadResponse = await fetch(`${route}/api/upload`, {
          method: 'POST',
          body: formDataPhotos,
        })
        if (!uploadResponse.ok) {
          await uploadResponse.json()
          throw new Error('Erro ao fazer upload das fotos.')
        }
        const uploadResult = await uploadResponse.json()
        if (Array.isArray(uploadResult)) {
          photoIds = uploadResult.map((photo: { id: number }) => photo.id)
        } else {
          throw new Error('Formato inesperado da resposta de upload.')
        }
      }

      // 1) Copiar dados do form (leisurePayloadData = { ...formData.leisure })
      const leisurePayloadData = { ...formData.leisure } as Partial<LeisureData>

      // 2) Se existirem uploadPhotos, fazer o upload e guardar os IDs retornados
      let leisurePhotoIds: number[] = []
      if (
        leisurePayloadData.uploadPhotos &&
        leisurePayloadData.uploadPhotos.length > 0
      ) {
        const fData = new FormData()
        leisurePayloadData.uploadPhotos.forEach((file) => {
          fData.append('files', file)
        })
        const leisureUploadResp = await fetch(`${route}/api/upload`, {
          method: 'POST',
          body: fData,
        })
        if (!leisureUploadResp.ok) {
          throw new Error('Erro ao fazer upload das fotos de Lazer.')
        }
        const upResult = await leisureUploadResp.json()
        // Transformar a resposta em array de IDs
        if (Array.isArray(upResult)) {
          leisurePhotoIds = upResult.map((p: { id: number }) => p.id)
        } else {
          throw new Error('Formato inesperado no upload das fotos de lazer.')
        }
      }

      const leisurePayload: LeisurePayload = {
        data: {
          id: leisurePayloadData.id ?? 0,
          linkPhotos: leisurePayloadData.linkPhotos || '',
          photos: leisurePhotoIds,
          partyHall: leisurePayloadData.partyHall ?? false,
          adultPool: leisurePayloadData.adultPool ?? false,
          gameRoom: leisurePayloadData.gameRoom ?? false,
          kidsPool: leisurePayloadData.kidsPool ?? false,
          gym: leisurePayloadData.gym ?? false,
          playground: leisurePayloadData.playground ?? false,
          sportsCourt: leisurePayloadData.sportsCourt ?? false,
          sauna: leisurePayloadData.sauna ?? false,
          tennisCourt: leisurePayloadData.tennisCourt ?? false,
          bbqArea: leisurePayloadData.bbqArea ?? false,
          squashCourt: leisurePayloadData.squashCourt ?? false,
          socialHall: leisurePayloadData.socialHall ?? false,
          wetDeckPool: leisurePayloadData.wetDeckPool ?? false,
          lapPool: leisurePayloadData.lapPool ?? false,
          kidsBeachPool: leisurePayloadData.kidsBeachPool ?? false,
          solarium: leisurePayloadData.solarium ?? false,
          poolBar: leisurePayloadData.poolBar ?? false,
          massageRoom: leisurePayloadData.massageRoom ?? false,
          womenSpace: leisurePayloadData.womenSpace ?? false,
          petPlace: leisurePayloadData.petPlace ?? false,
          youthGameRoom: leisurePayloadData.youthGameRoom ?? false,
          toyLibrary: leisurePayloadData.toyLibrary ?? false,
          pilatesSpace: leisurePayloadData.pilatesSpace ?? false,
          lounge: leisurePayloadData.lounge ?? false,
          gourmetSpace: leisurePayloadData.gourmetSpace ?? false,
          kidsPartyRoom: leisurePayloadData.kidsPartyRoom ?? false,
          adultPartyRoomWithBar:
            leisurePayloadData.adultPartyRoomWithBar ?? false,
          adultGameRoom: leisurePayloadData.adultGameRoom ?? false,
          readingRoom: leisurePayloadData.readingRoom ?? false,
          hammockArea: leisurePayloadData.hammockArea ?? false,
          zenSpace: leisurePayloadData.zenSpace ?? false,
          coworkingSpace: leisurePayloadData.coworkingSpace ?? false,
          adultTVRoom: leisurePayloadData.adultTVRoom ?? false,
          gourmetBar: leisurePayloadData.gourmetBar ?? false,
          cinemaRoom: leisurePayloadData.cinemaRoom ?? false,
          property: {
            data: leisurePayloadData.property?.data ?? null,
          },
        },
      }

      // Enviar o Lazer para Strapi
      const leisureResponse = await fetch(`${route}/api/leisures`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(leisurePayload),
      })
      if (!leisureResponse.ok) {
        throw new Error('Erro ao cadastrar os dados de lazer.')
      }

      const leisureResult = await leisureResponse.json()
      const leisureId = leisureResult.data.id

      // 3) Criar a Property
      const lastEditor = localStorage.getItem('userName')

      const propertyPayload: PropertyPayload = {
        data: {
          region: formData.region,
          street: formData.street,
          neighborhood: formData.neighborhood,
          number: formData.number,
          zipCode: formData.zipCode,
          totalArea: formData.totalArea,
          usableArea: formData.usableArea,
          commonArea: formData.commonArea,
          privateArea: formData.privateArea,
          status: formData.status,
          internalObservation: formData.internalObservation,
          externalObservation: formData.externalObservation,
          distanceFromBeach: formData.distanceFromBeach,
          price: formData.price,
          condominiumFee: formData.condominiumFee,
          barter: formData.barter,
          financing: formData.financing,
          paymentMethod: formData.paymentMethod,
          iptu: formData.iptu,
          seaView: formData.seaView,
          owner: formData.owner,
          ...(formData.block ? { block: formData.block } : { block: null }),
          propertyType: formData.propertyType,
          photos: photoIds, // associa as fotos enviadas
          leisure: leisureId, // associa o Lazer criado
          linkPhotos: formData.linkPhotos,
          ...(selectedCondominium
            ? { condominium: selectedCondominium.id }
            : {}),
          // Copiando campos específicos do tipo
          ...(formData.propertyType === 'apartment' && formData.apartment
            ? { ...formData.apartment }
            : {}),
          ...(formData.propertyType === 'house' && formData.house
            ? { ...formData.house }
            : {}),
          ...(formData.propertyType === 'commercial_property' &&
          formData.commercial_property
            ? { ...formData.commercial_property }
            : {}),
          ...(formData.propertyType === 'area' && formData.area
            ? { ...formData.area }
            : {}),
          ...(formData.propertyType === 'terrain' && formData.terrain
            ? { ...formData.terrain }
            : {}),
          lastEditor: lastEditor ?? '',
        },
      }

      const propertyResponse = await fetch(`${route}/api/properties`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(propertyPayload),
      })

      if (!propertyResponse.ok) {
        throw new Error('Erro ao cadastrar a propriedade.')
      }

      await propertyResponse.json()
      toast({
        title: 'Sucesso',
        description: 'Imóvel cadastrado com sucesso!',
      })
      navigateHome()
    } catch (error) {
      toast({
        title: 'Erro',
        description: 'Erro ao cadastrar o Imóvel. Por favor, tente novamente.',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex h-full w-full flex-col bg-gray-100 p-8">
      <h1 className="mb-6 text-center text-3xl font-bold text-gray-800">
        Cadastro de Imóveis
      </h1>
      <div className="rounded-lg bg-white p-6 shadow-md">
        <OwnerSelector setFormData={setFormData} />
        <CondominiumSelector
          formData={formData}
          setFormData={setFormData}
          setSelectedCondominium={setSelectedCondominium}
        />
        <Address
          data={formData}
          onChange={handleChange}
          condominium={selectedCondominium}
        />
        <PropertyOptions formData={formData} setFormData={setFormData} />
        <PropertyTypeForm formData={formData} setFormData={setFormData} />
        <Leisure
          data={formData.leisure}
          onChange={handleLeisureChange}
          onCheckboxChange={handleLeisureCheckboxChange}
        />
        <PhotoUpload
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
        <div className="mt-6 flex justify-end">
          <Button
            onClick={handleSubmit}
            disabled={loading}
            className="bg-indigo-600 text-white hover:bg-indigo-700"
          >
            {loading ? 'Enviando...' : 'Enviar'}
          </Button>
        </div>
      </div>
      <Toaster />
      <Footer />
    </div>
  )
}
