// src/iconLibrary.ts

import { library } from '@fortawesome/fontawesome-svg-core'
// Importe apenas os ícones que realmente usa
import {
  faAlignJustify,
  faArrowUp,
  faBath,
  faBed,
  faBolt,
  faBorderStyle,
  faBoxOpen,
  faBreadSlice,
  faBriefcase,
  faBuilding,
  faCalendarAlt,
  faCalendarCheck,
  faCamera,
  faCar,
  faChair,
  faChartArea,
  faCheck,
  faCheckCircle,
  faCircle,
  faCoffee,
  faCog,
  faCogs,
  faCouch,
  faCreditCard,
  faDollarSign,
  faDoorOpen,
  faDownload,
  faExchange,
  faExchangeAlt,
  faHandHoldingUsd,
  faHardHat,
  faHashtag,
  faHouseUser,
  faInfo,
  faKey,
  faLayerGroup,
  faLink,
  faList,
  faMapMarkerAlt,
  faMapPin,
  faMoneyBillWave,
  faPhoneAlt,
  faPrint,
  faReceipt,
  faRestroom,
  faRoad,
  faRulerCombined,
  faShieldAlt,
  faStickyNote,
  faSwimmer,
  faTint,
  faToilet,
  faTools,
  faUmbrellaBeach,
  faUserSecret,
  faUserShield,
  faUserTie,
  faWater,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faDollarSign,
  faChartArea,
  faRulerCombined,
  faMapMarkerAlt,
  faBed,
  faBath,
  faToilet,
  faCar,
  faReceipt,
  faBuilding,
  faUmbrellaBeach,
  faWater,
  faCheckCircle,
  faHardHat,
  faUserTie,
  faPhoneAlt,
  faUserShield,
  faCalendarAlt,
  faCalendarCheck,
  faCogs,
  faCog,
  faBriefcase,
  faBorderStyle,
  faShieldAlt,
  faRoad,
  faHashtag,
  faMapPin,
  faLayerGroup,
  faExchangeAlt,
  faHandHoldingUsd,
  faCreditCard,
  faRestroom,
  faArrowUp,
  faChair,
  faStickyNote,
  faKey,
  faBoxOpen,
  faTools,
  faCoffee,
  faCheck,
  faAlignJustify,
  faTint,
  faBolt,
  faHouseUser,
  faDoorOpen,
  faBreadSlice,
  faUserSecret,
  faCamera,
  faLink,
  faMoneyBillWave,
  faCouch,
  faList,
  faInfo,
  faSwimmer,
  faDownload,
  faPrint,
  faCircle,
  faExchange,
)
