// CreateCondominiumDialog.tsx

import * as Toast from '@radix-ui/react-toast'
import React, { useEffect, useState } from 'react'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

import { Leisure } from '../form-property/leisure'
import {
  Condominium,
  LeisureData,
  Region,
} from '../form-property/property-types'
import { Button } from '../ui/button'
import { Checkbox } from '../ui/checkbox'
import { Input } from '../ui/input'
import { Label } from '../ui/label'

interface CreateCondominiumDialogProps {
  onCondominiumCreate: (condominium: Condominium) => void
  trigger?: React.ReactNode
  existingCondominium?: Condominium
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export function CreateCondominiumDialog({
  onCondominiumCreate,
  trigger,
  existingCondominium,
  open,
  onOpenChange,
}: CreateCondominiumDialogProps) {
  const [name, setName] = useState('')
  const [region, setRegion] = useState('')
  const [street, setStreet] = useState('')
  const [neighborhood, setNeighborhood] = useState('')
  const [number, setNumber] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [commercialStatus, setCommercialStatus] = useState('')
  const [builderName, setBuilderName] = useState('')
  const [developerName, setDeveloperName] = useState('')
  const [managerName, setManagerName] = useState('')
  const [managerPhone, setManagerPhone] = useState('')
  const [caretakerName, setCaretakerName] = useState('')
  const [caretakerPhone, setCaretakerPhone] = useState('')
  const [constructionYear, setConstructionYear] = useState('')
  const [numberOfServiceElevators, setNumberOfServiceElevators] = useState('')
  const [numberOfPassengerElevators, setNumberOfPassengerElevators] =
    useState('')
  const [deliverySchedule, setDeliverySchedule] = useState('')
  const [corner, setCorner] = useState(false)
  const [guardhouse, setGuardhouse] = useState(false)
  const [intercom, setIntercom] = useState(false)
  const [terrace, setTerrace] = useState(false)
  const [undergroundGarage, setUndergroundGarage] = useState(false)
  const [beachService, setBeachService] = useState(false)
  const [beachEquipmentStorage, setBeachEquipmentStorage] = useState(false)
  const [electronicGate, setElectronicGate] = useState(false)
  const [groundFloorGarage, setGroundFloorGarage] = useState(false)
  const [collectiveGas, setCollectiveGas] = useState(false)
  const [leisureData, setLeisureData] = useState<LeisureData>({
    id: 0,
    partyHall: false,
    adultPool: false,
    gameRoom: false,
    kidsPool: false,
    gym: false,
    playground: false,
    sportsCourt: false,
    sauna: false,
    tennisCourt: false,
    bbqArea: false,
    squashCourt: false,
    socialHall: false,
    wetDeckPool: false,
    lapPool: false,
    kidsBeachPool: false,
    solarium: false,
    poolBar: false,
    massageRoom: false,
    womenSpace: false,
    petPlace: false,
    youthGameRoom: false,
    toyLibrary: false,
    pilatesSpace: false,
    lounge: false,
    gourmetSpace: false,
    kidsPartyRoom: false,
    adultPartyRoomWithBar: false,
    adultGameRoom: false,
    readingRoom: false,
    hammockArea: false,
    zenSpace: false,
    coworkingSpace: false,
    adultTVRoom: false,
    gourmetBar: false,
    cinemaRoom: false,
    property: { data: null },
    photos: { connect: [] },
    uploadPhotos: [],
    linkPhotos: '',
  })
  const [toastOpen, setToastOpen] = useState(false)
  const [toastType, setToastType] = useState<'success' | 'error'>('success')
  const [toastMessage, setToastMessage] = useState('')
  const [regionOptions, setRegionOptions] = useState<string[]>([])
  const [commercialStatusOptions, setCommercialStatusOptions] = useState<
    string[]
  >([])

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const route = import.meta.env.VITE_URL_ENDPOINT
        const response = await fetch(`${route}/api/regions`)
        const data = await response.json()
        const mapped = data.data.map((r: Region) => r.attributes.Name)
        setRegionOptions(mapped)
      } catch (error) {
        console.error('Erro ao buscar regiões:', error)
      }
    }
    fetchRegions()
  }, [])

  useEffect(() => {
    setCommercialStatusOptions(['Disponível', 'Vendido', 'Pendente'])
  }, [])

  useEffect(() => {
    const fetchAddress = async () => {
      const sanitized = zipCode.replace(/\D/g, '')
      if (sanitized.length === 8) {
        try {
          const response = await fetch(
            `https://viacep.com.br/ws/${sanitized}/json/`,
          )
          const data = await response.json()
          if (!data.erro) {
            setStreet(data.logradouro || '')
            setNeighborhood(data.bairro || '')
          } else {
            setToastType('error')
            setToastMessage('CEP não encontrado.')
            setToastOpen(true)
          }
        } catch (error) {
          console.error('Erro ao buscar o endereço:', error)
          setToastType('error')
          setToastMessage('Erro ao buscar o endereço.')
          setToastOpen(true)
        }
      }
    }
    if (zipCode) {
      fetchAddress()
    }
  }, [zipCode])

  useEffect(() => {
    if (existingCondominium) {
      const a = existingCondominium.attributes
      setName(a.Name || '')
      setRegion(a.region || '')
      setStreet(a.street || '')
      setNeighborhood(a.neighborhood || '')
      setNumber(a.number || '')
      setZipCode(a.zipCode || '')
      setCommercialStatus(a.commercialStatus || '')
      setBuilderName(a.builderName || '')
      setDeveloperName(a.developerName || '')
      setManagerName(a.managerName || '')
      setManagerPhone(a.managerPhone || '')
      setCaretakerName(a.caretakerName || '')
      setCaretakerPhone(a.caretakerPhone || '')
      setConstructionYear(a.constructionYear || '')
      setNumberOfServiceElevators(a.numberOfServiceElevators || '')
      setNumberOfPassengerElevators(a.numberOfPassengerElevators || '')
      setDeliverySchedule(a.deliverySchedule || '')
      setCorner(a.corner || false)
      setGuardhouse(a.guardhouse || false)
      setIntercom(a.intercom || false)
      setTerrace(a.terrace || false)
      setUndergroundGarage(a.undergroundGarage || false)
      setBeachService(a.beachService || false)
      setBeachEquipmentStorage(a.beachEquipmentStorage || false)
      setElectronicGate(a.electronicGate || false)
      setGroundFloorGarage(a.groundFloorGarage || false)
      setCollectiveGas(a.collectiveGas || false)
    } else {
      setName('')
      setRegion('')
      setStreet('')
      setNeighborhood('')
      setNumber('')
      setZipCode('')
      setCommercialStatus('')
      setBuilderName('')
      setDeveloperName('')
      setManagerName('')
      setManagerPhone('')
      setCaretakerName('')
      setCaretakerPhone('')
      setConstructionYear('')
      setNumberOfServiceElevators('')
      setNumberOfPassengerElevators('')
      setDeliverySchedule('')
      setCorner(false)
      setGuardhouse(false)
      setIntercom(false)
      setTerrace(false)
      setUndergroundGarage(false)
      setBeachService(false)
      setBeachEquipmentStorage(false)
      setElectronicGate(false)
      setGroundFloorGarage(false)
      setCollectiveGas(false)
    }
  }, [existingCondominium])

  const handleLeisureCheckboxChange = (
    name: keyof LeisureData,
    checked: boolean | 'indeterminate',
  ) => {
    setLeisureData((p) => ({ ...p, [name]: checked === true }))
  }

  const handleLeisureChange = (field: keyof LeisureData, value: unknown) => {
    setLeisureData((p) => ({ ...p, [field]: value }))
  }

  function formatPhoneNumber(value: string): string {
    value = value.replace(/\D/g, '') // Remove tudo que não for dígito

    if (value.length <= 2) {
      return `(${value}`
    } else if (value.length <= 6) {
      return `(${value.slice(0, 2)}) ${value.slice(2)}`
    } else if (value.length <= 10) {
      return `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6)}`
    } else {
      return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`
    }
  }

  async function handleCreateCondominium() {
    try {
      const route = import.meta.env.VITE_URL_ENDPOINT
      let leisureId = null

      if (!existingCondominium) {
        let photoIds: number[] = []
        if (leisureData.uploadPhotos && leisureData.uploadPhotos.length > 0) {
          const fData = new FormData()
          leisureData.uploadPhotos.forEach((file) => {
            if (file instanceof File) {
              fData.append('files', file)
            }
          })
          const uploadResp = await fetch(`${route}/api/upload`, {
            method: 'POST',
            body: fData,
          })
          if (!uploadResp.ok) {
            const upErr = await uploadResp.json()
            console.error('Erro no upload das fotos:', upErr)
            throw new Error('Erro ao fazer upload das fotos do Lazer.')
          }
          const upResult = await uploadResp.json()
          if (Array.isArray(upResult)) {
            photoIds = upResult.map((p: { id: number }) => p.id)
          } else {
            console.error('Resposta de upload inesperada:', upResult)
            throw new Error('Formato inesperado no upload de fotos.')
          }
        }
        const leisurePayload = {
          data: {
            partyHall: leisureData.partyHall,
            adultPool: leisureData.adultPool,
            gameRoom: leisureData.gameRoom,
            kidsPool: leisureData.kidsPool,
            gym: leisureData.gym,
            playground: leisureData.playground,
            sportsCourt: leisureData.sportsCourt,
            sauna: leisureData.sauna,
            tennisCourt: leisureData.tennisCourt,
            bbqArea: leisureData.bbqArea,
            squashCourt: leisureData.squashCourt,
            socialHall: leisureData.socialHall,
            wetDeckPool: leisureData.wetDeckPool,
            lapPool: leisureData.lapPool,
            kidsBeachPool: leisureData.kidsBeachPool,
            solarium: leisureData.solarium,
            poolBar: leisureData.poolBar,
            massageRoom: leisureData.massageRoom,
            womenSpace: leisureData.womenSpace,
            petPlace: leisureData.petPlace,
            youthGameRoom: leisureData.youthGameRoom,
            toyLibrary: leisureData.toyLibrary,
            pilatesSpace: leisureData.pilatesSpace,
            lounge: leisureData.lounge,
            gourmetSpace: leisureData.gourmetSpace,
            kidsPartyRoom: leisureData.kidsPartyRoom,
            adultPartyRoomWithBar: leisureData.adultPartyRoomWithBar,
            adultGameRoom: leisureData.adultGameRoom,
            readingRoom: leisureData.readingRoom,
            hammockArea: leisureData.hammockArea,
            zenSpace: leisureData.zenSpace,
            coworkingSpace: leisureData.coworkingSpace,
            adultTVRoom: leisureData.adultTVRoom,
            gourmetBar: leisureData.gourmetBar,
            cinemaRoom: leisureData.cinemaRoom,
            linkPhotos: leisureData.linkPhotos,
            photos: photoIds,
          },
        }
        const leisureResp = await fetch(`${route}/api/leisures`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(leisurePayload),
        })
        if (!leisureResp.ok) {
          const leisureError = await leisureResp.json()
          console.error('Erro ao criar Lazer:', leisureError)
          throw new Error('Erro ao cadastrar Lazer.')
        }
        const leisureJson = await leisureResp.json()
        leisureId = leisureJson.data.id
      }

      const condPayload = {
        data: {
          Name: name,
          region,
          street,
          neighborhood,
          number,
          zipCode,
          commercialStatus,
          builderName,
          developerName,
          managerName,
          managerPhone,
          caretakerName,
          caretakerPhone,
          constructionYear,
          numberOfServiceElevators,
          numberOfPassengerElevators,
          deliverySchedule,
          corner,
          guardhouse,
          intercom,
          terrace,
          undergroundGarage,
          beachService,
          beachEquipmentStorage,
          electronicGate,
          groundFloorGarage,
          collectiveGas,
          leisure: leisureId || undefined,
        },
      }

      const method = existingCondominium ? 'PUT' : 'POST'
      const url = existingCondominium
        ? `${route}/api/condominiums/${existingCondominium.id}`
        : `${route}/api/condominiums`
      const resp = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(condPayload),
      })
      if (!resp.ok) {
        const errData = await resp.json()
        const msg = errData?.message || 'Falha ao salvar o condomínio'
        throw new Error(msg)
      }
      const newData = await resp.json()
      const savedCondominium = newData.data
      onCondominiumCreate(savedCondominium)
      setToastType('success')
      setToastMessage(
        existingCondominium
          ? 'Condomínio atualizado com sucesso!'
          : 'Condomínio criado com sucesso!',
      )
      setToastOpen(true)
      onOpenChange && onOpenChange(false)
    } catch (error) {
      onOpenChange && onOpenChange(false)
      console.error('Failed to save condominium:', error)
      setToastType('error')
      setToastMessage('Falha ao salvar o condomínio!')
      setToastOpen(true)
    }
  }

  return (
    <div>
      <Toast.Provider swipeDirection="right">
        <Dialog open={open} onOpenChange={onOpenChange}>
          {trigger ? <DialogTrigger asChild>{trigger}</DialogTrigger> : null}
          <DialogContent className="max-h-[90vh] overflow-y-auto rounded-md bg-white p-6 shadow-lg sm:max-w-[800px]">
            <DialogHeader>
              <DialogTitle className="text-xl font-bold">
                {existingCondominium ? 'Editar Condomínio' : 'Criar Condomínio'}
              </DialogTitle>
              <DialogDescription className="text-sm text-gray-500">
                Preencha as informações e clique em salvar
              </DialogDescription>
            </DialogHeader>

            <Accordion type="multiple" className="w-full">
              <AccordionItem value="basicInfo">
                <AccordionTrigger className="text-lg font-medium">
                  Dados Básicos
                </AccordionTrigger>
                <AccordionContent>
                  <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="name" className="text-right">
                        Nome
                      </Label>
                      <Input
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="commercialStatus" className="text-right">
                        Status Comercial
                      </Label>
                      <Select
                        value={commercialStatus}
                        onValueChange={(val) => setCommercialStatus(val)}
                      >
                        <SelectTrigger className="col-span-3">
                          <SelectValue placeholder="Selecione o Status Comercial" />
                        </SelectTrigger>
                        <SelectContent>
                          {commercialStatusOptions.map((st) => (
                            <SelectItem key={st} value={st}>
                              {st}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="address">
                <AccordionTrigger className="text-lg font-medium">
                  Endereço
                </AccordionTrigger>
                <AccordionContent>
                  <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="zipCode" className="text-right">
                        CEP
                      </Label>
                      <Input
                        id="zipCode"
                        value={zipCode}
                        onChange={(e) => {
                          const c = e.target.value.replace(/\D/g, '')
                          setZipCode(c)
                        }}
                        className="col-span-3"
                        maxLength={8}
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="street" className="text-right">
                        Rua
                      </Label>
                      <Input
                        id="street"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="number" className="text-right">
                        Número
                      </Label>
                      <Input
                        id="number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="neighborhood" className="text-right">
                        Bairro
                      </Label>
                      <Input
                        id="neighborhood"
                        value={neighborhood}
                        onChange={(e) => setNeighborhood(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="region" className="text-right">
                        Região
                      </Label>
                      <Select
                        value={region}
                        onValueChange={(val) => setRegion(val)}
                      >
                        <SelectTrigger className="col-span-3">
                          <SelectValue placeholder="Selecione a Região" />
                        </SelectTrigger>
                        <SelectContent>
                          {regionOptions.map((op) => (
                            <SelectItem key={op} value={op}>
                              {op}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="condoInfo">
                <AccordionTrigger className="text-lg font-medium">
                  Informações do Condomínio
                </AccordionTrigger>
                <AccordionContent>
                  <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="builderName" className="text-right">
                        Nome da Construtora
                      </Label>
                      <Input
                        id="builderName"
                        value={builderName}
                        onChange={(e) => setBuilderName(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="developerName" className="text-right">
                        Nome da Incorporadora
                      </Label>
                      <Input
                        id="developerName"
                        value={developerName}
                        onChange={(e) => setDeveloperName(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="constructionYear" className="text-right">
                        Ano de Construção
                      </Label>
                      <Input
                        id="constructionYear"
                        value={constructionYear}
                        onChange={(e) => setConstructionYear(e.target.value)}
                        className="col-span-3"
                        type="number"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="deliverySchedule" className="text-right">
                        Previsão de Entrega
                      </Label>
                      <Input
                        id="deliverySchedule"
                        value={deliverySchedule}
                        onChange={(e) => setDeliverySchedule(e.target.value)}
                        className="col-span-3"
                        type="date"
                      />
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="contacts">
                <AccordionTrigger className="text-lg font-medium">
                  Contatos
                </AccordionTrigger>
                <AccordionContent>
                  <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="managerName" className="text-right">
                        Nome do Sindico
                      </Label>
                      <Input
                        id="managerName"
                        value={managerName}
                        onChange={(e) => setManagerName(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="managerPhone" className="text-right">
                        Telefone do Sindico
                      </Label>
                      <Input
                        id="managerPhone"
                        value={managerPhone}
                        onChange={(e) =>
                          setManagerPhone(formatPhoneNumber(e.target.value))
                        }
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="caretakerName" className="text-right">
                        Nome do Zelador
                      </Label>
                      <Input
                        id="caretakerName"
                        value={caretakerName}
                        onChange={(e) => setCaretakerName(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="caretakerPhone" className="text-right">
                        Telefone do Zelador
                      </Label>
                      <Input
                        id="caretakerPhone"
                        value={caretakerPhone}
                        onChange={(e) =>
                          setCaretakerPhone(formatPhoneNumber(e.target.value))
                        }
                        className="col-span-3"
                      />
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="features">
                <AccordionTrigger className="text-lg font-medium">
                  Características
                </AccordionTrigger>
                <AccordionContent>
                  <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label
                        htmlFor="numberOfServiceElevators"
                        className="text-right"
                      >
                        Nº de Elevadores de Serviço
                      </Label>
                      <Input
                        id="numberOfServiceElevators"
                        value={numberOfServiceElevators}
                        onChange={(e) =>
                          setNumberOfServiceElevators(e.target.value)
                        }
                        className="col-span-3"
                        type="number"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label
                        htmlFor="numberOfPassengerElevators"
                        className="text-right"
                      >
                        Nº de Elevadores de Passageiros
                      </Label>
                      <Input
                        id="numberOfPassengerElevators"
                        value={numberOfPassengerElevators}
                        onChange={(e) =>
                          setNumberOfPassengerElevators(e.target.value)
                        }
                        className="col-span-3"
                        type="number"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="corner" className="text-right">
                        Esquina
                      </Label>
                      <Checkbox
                        id="corner"
                        checked={corner}
                        onCheckedChange={(ch) => setCorner(ch === true)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="guardhouse" className="text-right">
                        Guarita
                      </Label>
                      <Checkbox
                        id="guardhouse"
                        checked={guardhouse}
                        onCheckedChange={(ch) => setGuardhouse(ch === true)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="intercom" className="text-right">
                        Interfone
                      </Label>
                      <Checkbox
                        id="intercom"
                        checked={intercom}
                        onCheckedChange={(ch) => setIntercom(ch === true)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="terrace" className="text-right">
                        Terraço
                      </Label>
                      <Checkbox
                        id="terrace"
                        checked={terrace}
                        onCheckedChange={(ch) => setTerrace(ch === true)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="undergroundGarage" className="text-right">
                        Garagem Subterrânea
                      </Label>
                      <Checkbox
                        id="undergroundGarage"
                        checked={undergroundGarage}
                        onCheckedChange={(ch) =>
                          setUndergroundGarage(ch === true)
                        }
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="beachService" className="text-right">
                        Serviço de Praia
                      </Label>
                      <Checkbox
                        id="beachService"
                        checked={beachService}
                        onCheckedChange={(ch) => setBeachService(ch === true)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label
                        htmlFor="beachEquipmentStorage"
                        className="text-right"
                      >
                        Depósito para Equipamentos de Praia
                      </Label>
                      <Checkbox
                        id="beachEquipmentStorage"
                        checked={beachEquipmentStorage}
                        onCheckedChange={(ch) =>
                          setBeachEquipmentStorage(ch === true)
                        }
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="electronicGate" className="text-right">
                        Portão Eletrônico
                      </Label>
                      <Checkbox
                        id="electronicGate"
                        checked={electronicGate}
                        onCheckedChange={(ch) => setElectronicGate(ch === true)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="groundFloorGarage" className="text-right">
                        Garagem no Térreo
                      </Label>
                      <Checkbox
                        id="groundFloorGarage"
                        checked={groundFloorGarage}
                        onCheckedChange={(ch) =>
                          setGroundFloorGarage(ch === true)
                        }
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="collectiveGas" className="text-right">
                        Gás Encanado
                      </Label>
                      <Checkbox
                        id="collectiveGas"
                        checked={collectiveGas}
                        onCheckedChange={(ch) => setCollectiveGas(ch === true)}
                        className="col-span-3"
                      />
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>

              {!existingCondominium && (
                <AccordionItem value="leisureInfo">
                  <AccordionTrigger className="text-lg font-medium">
                    Lazer
                  </AccordionTrigger>
                  <AccordionContent>
                    <Leisure
                      data={leisureData}
                      onChange={handleLeisureChange}
                      onCheckboxChange={handleLeisureCheckboxChange}
                    />
                  </AccordionContent>
                </AccordionItem>
              )}
            </Accordion>

            <DialogFooter>
              <Button type="button" onClick={handleCreateCondominium}>
                Salvar
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Toast.Root
          open={toastOpen}
          onOpenChange={setToastOpen}
          duration={5000}
          className={`fixed bottom-0 right-0 m-6 rounded-md p-4 shadow-md ${
            toastType === 'success'
              ? 'bg-green-500 text-white'
              : 'bg-red-500 text-white'
          }`}
        >
          <Toast.Description>{toastMessage}</Toast.Description>
          <Toast.Close className="absolute right-1 top-1" />
        </Toast.Root>
        <Toast.Viewport />
      </Toast.Provider>
    </div>
  )
}
