import { Search } from 'lucide-react'
import { useEffect, useState } from 'react'

import { Footer } from '@/components/Footer'
import { Header } from '@/components/Header'
import { OwnerCard } from '@/components/owner/OwnerCard'
import { CreateOwnerDialog } from '@/components/owner/OwnerCreateDialog'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

interface OwnerAttributes {
  name: string
  telephone: string
  cell_phone: string
  email: string
  observation: string
}

interface Owner {
  id: number
  name: string
  telephone: string
  cell_phone: string
  email: string
  observation: string
}

interface OwnerData {
  id: number
  attributes: OwnerAttributes
}

interface ApiResponse {
  data: OwnerData[]
}

export function OwnerControl() {
  const [owners, setOwners] = useState<Owner[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filterType, setFilterType] = useState<'name' | 'email'>('name')

  const fetchOwners = async () => {
    const route = import.meta.env.VITE_URL_ENDPOINT
    try {
      const response = await fetch(`${route}/api/owners`)
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data: ApiResponse = await response.json()

      const ownersData = data.data.map((item) => ({
        id: item.id, // Aqui o `id` é extraído
        name: item.attributes.name,
        telephone: item.attributes.telephone,
        cell_phone: item.attributes.cell_phone,
        email: item.attributes.email,
        observation: item.attributes.observation,
      }))

      setOwners(ownersData)
    } catch (error) {
      console.error('Failed to fetch owners:', error)
      setOwners([]) // Garante que owners seja um array mesmo em caso de erro
    }
  }

  useEffect(() => {
    fetchOwners()
  }, [])

  const filteredOwners = owners.filter((owner) => {
    const searchField = filterType === 'name' ? owner.name : owner.email
    return (
      searchField &&
      searchField.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })

  const headerContent = (
    <div className="w-full max-w-4xl">
      <h1 className="-mt-5 mb-5 text-center text-2xl font-bold">
        Cadastro de Proprietários
      </h1>
      <div className="flex flex-col space-y-4">
        <div className="flex space-x-4">
          <div className="relative flex-grow">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <Search className="text-gray-400" />
            </span>
            <Input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10"
              placeholder={`Buscar por ${
                filterType === 'name' ? 'nome' : 'email'
              } do proprietário`}
            />
          </div>
          <Select
            value={filterType}
            onValueChange={(value: 'name' | 'email') => setFilterType(value)}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Filtrar por" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="name">Nome</SelectItem>
              <SelectItem value="email">Email</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <CreateOwnerDialog onOwnerCreate={fetchOwners} trigger={<></>} />
      </div>
    </div>
  )

  return (
    <div className="min-h-screen">
      <Header filterContent={headerContent} />

      <div className="p-8">
        <div className="mx-auto max-w-4xl">
          <h1 className="mb-3 text-center text-2xl font-bold">
            Lista de Proprietários
          </h1>
          <div className="mt-4">
            {filteredOwners.length > 0 ? (
              filteredOwners.map((owner) => (
                <OwnerCard
                  key={owner.id}
                  id={owner.id}
                  name={owner.name}
                  telephone={owner.telephone}
                  cell_phone={owner.cell_phone}
                  email={owner.email}
                  observation={owner.observation}
                  onOwnerUpdate={fetchOwners}
                />
              ))
            ) : (
              <h2 className="mt-10">
                Não possui proprietário cadastrado no sistema
              </h2>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
