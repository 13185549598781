// src/App.tsx

import './global.css' // Importar estilos globais
import './fontAwesome' // Importar configuração do Font Awesome
import './lib/icons' // Importa o arquivo que registra os ícones

import { Helmet, HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from 'sonner'

import { router } from './routes'

export function App() {
  return (
    <HelmetProvider>
      <Helmet titleTemplate="Sistema Iguatemar" />
      <Toaster richColors />
      <RouterProvider router={router} />
    </HelmetProvider>
  )
}
