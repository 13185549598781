// Address.tsx

import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'

import { PropertyFormData } from '@/components/form-property/property-types'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

// Importa os tipos necessários
import { Condominium } from '../form-property/property-types'

export interface Region {
  id: number
  attributes: {
    Name: string
    Observation?: string
  }
}

interface AddressProps {
  data: PropertyFormData
  onChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => void
  condominium?: Condominium
}

export function Address({ data, onChange, condominium }: AddressProps) {
  const [regions, setRegions] = useState<Region[]>([])

  const route = import.meta.env.VITE_URL_ENDPOINT

  // Busca as regiões do Strapi
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await fetch(`${route}/api/regions`)
        const result = await response.json()
        setRegions(result.data)
      } catch (error) {
        console.error('Erro ao buscar regiões:', error)
      }
    }

    fetchRegions()
  }, [route])

  // Importa dados do condomínio se fornecido
  useEffect(() => {
    if (condominium) {
      const attrs = condominium.attributes

      const updateFields = [
        { name: 'region', value: attrs.region },
        { name: 'street', value: attrs.street },
        { name: 'neighborhood', value: attrs.neighborhood },
        { name: 'number', value: attrs.number },
        { name: 'zipCode', value: attrs.zipCode },
      ]

      updateFields.forEach((field) => {
        onChange({
          target: {
            name: field.name,
            value: field.value || '',
          },
        } as React.ChangeEvent<HTMLInputElement>)
      })
    }
  }, [condominium, onChange])

  // Função para formatar o CEP
  const handleCepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Apenas passa o evento para o componente pai sem modificar
    onChange(e)
  }

  // Busca o endereço pelo CEP
  useEffect(() => {
    const fetchAddressFromCep = async (cep: string) => {
      const sanitizedCep = cep.replace(/\D/g, '') // Remove todos os caracteres não numéricos
      if (sanitizedCep.length === 8) {
        try {
          const response = await fetch(
            `https://viacep.com.br/ws/${sanitizedCep}/json/`,
          )
          const addressData = await response.json()
          if (!addressData.erro) {
            const streetEvent = {
              target: {
                name: 'street',
                value: addressData.logradouro || '',
              },
            } as React.ChangeEvent<HTMLInputElement>
            const neighborhoodEvent = {
              target: {
                name: 'neighborhood',
                value: addressData.bairro || '',
              },
            } as React.ChangeEvent<HTMLInputElement>

            onChange(streetEvent)
            onChange(neighborhoodEvent)
          } else {
            console.error('CEP não encontrado.')
          }
        } catch (error) {
          console.error('Erro ao buscar endereço:', error)
        }
      }
    }

    if (data.zipCode) {
      fetchAddressFromCep(data.zipCode)
    }
  }, [data.zipCode, onChange])

  return (
    <div className="flex h-full w-full flex-col bg-gray-100 p-8">
      <h1 className="mb-6 text-center text-3xl font-bold text-gray-800">
        Detalhes do Imóvel
      </h1>

      <h2 className="mb-4 mt-6 text-2xl font-semibold text-gray-700">
        Dados do Imóvel
      </h2>

      {/* CEP, Status e Região */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {/* CEP */}
        <div>
          <Label htmlFor="zipCode" className="text-gray-600">
            CEP
          </Label>
          <Input
            type="text"
            id="zipCode"
            name="zipCode"
            placeholder="CEP (Ex: 12345678)"
            value={data.zipCode || ''}
            onChange={handleCepChange}
            maxLength={8} // Limita a 8 caracteres
            className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>

        {/* Status */}
        <div>
          <Label htmlFor="status" className="text-gray-600">
            Status
          </Label>
          <Select
            value={data.status || ''}
            onValueChange={(value: string) =>
              onChange({
                target: {
                  name: 'status',
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>)
            }
          >
            <SelectTrigger
              id="status"
              name="status"
              className="mt-1 w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <SelectValue placeholder="Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="À venda">À venda</SelectItem>
              <SelectItem value="Vendido">Vendido</SelectItem>
              <SelectItem value="Suspenso">Suspenso</SelectItem>
              <SelectItem value="Locação">Locação</SelectItem>
              <SelectItem value="Disponível">Disponível</SelectItem>
              <SelectItem value="Lançamento">Lançamento</SelectItem>
            </SelectContent>
          </Select>
        </div>

        {/* Região */}
        <div>
          <Label htmlFor="region" className="text-gray-600">
            Região
          </Label>
          <Select
            value={data.region || ''}
            onValueChange={(value: string) =>
              onChange({
                target: {
                  name: 'region',
                  value,
                },
              } as React.ChangeEvent<HTMLInputElement>)
            }
          >
            <SelectTrigger
              id="region"
              name="region"
              className="mt-1 w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <SelectValue placeholder="Selecione a Região" />
            </SelectTrigger>
            <SelectContent>
              {regions.map((region) => (
                <SelectItem key={region.id} value={region.attributes.Name}>
                  {region.attributes.Name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* Demais Campos */}
      <div className="mt-6 grid grid-cols-1 gap-4 md:grid-cols-3">
        {/* Rua */}
        <div>
          <Label htmlFor="street" className="text-gray-600">
            Rua
          </Label>
          <Input
            type="text"
            id="street"
            name="street"
            placeholder="Rua"
            value={data.street || ''}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>

        {/* Bairro */}
        <div>
          <Label htmlFor="neighborhood" className="text-gray-600">
            Bairro
          </Label>
          <Input
            type="text"
            id="neighborhood"
            name="neighborhood"
            placeholder="Bairro"
            value={data.neighborhood || ''}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>

        {/* Número */}
        <div>
          <Label htmlFor="number" className="text-gray-600">
            Número
          </Label>
          <Input
            type="text"
            id="number"
            name="number"
            placeholder="Número"
            value={data.number || ''}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
      </div>

      {/* Áreas */}
      <h2 className="mb-4 mt-6 text-xl font-semibold text-gray-700">Áreas</h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
        {/* Total */}
        <div>
          <Label htmlFor="totalArea" className="text-gray-600">
            Área Total
          </Label>
          <Input
            type="text"
            id="totalArea"
            name="totalArea"
            placeholder="Total (m²)"
            value={data.totalArea || ''}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
        {/* Utilizável */}
        <div>
          <Label htmlFor="usableArea" className="text-gray-600">
            Área Útil (privativa)
          </Label>
          <Input
            type="text"
            id="usableArea"
            name="usableArea"
            placeholder="Utilizável (m²)"
            value={data.usableArea || ''}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
        {/* Comum */}
        <div>
          <Label htmlFor="commonArea" className="text-gray-600">
            Área Comum
          </Label>
          <Input
            type="text"
            id="commonArea"
            name="commonArea"
            placeholder="Comum (m²)"
            value={data.commonArea || ''}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
      </div>

      {/* Detalhes Adicionais */}
      <h2 className="mb-4 mt-6 text-xl font-semibold text-gray-700">
        Detalhes Adicionais
      </h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {/* Distância da Praia */}
        <div>
          <Label htmlFor="distanceFromBeach" className="text-gray-600">
            Distância da Praia
          </Label>
          <Input
            type="text"
            id="distanceFromBeach"
            name="distanceFromBeach"
            placeholder="Distância da Praia (m)"
            value={data.distanceFromBeach || ''}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
        {/* Preço */}
        <div>
          <Label htmlFor="price" className="text-gray-600">
            Preço
          </Label>
          <CurrencyInput
            id="price"
            name="price"
            placeholder="Preço"
            value={data.price}
            decimalsLimit={2}
            decimalSeparator=","
            groupSeparator="."
            prefix="R$ "
            onValueChange={(value, name) => {
              onChange({
                target: {
                  name: name || '',
                  value: value || '',
                },
              } as React.ChangeEvent<HTMLInputElement>)
            }}
            customInput={Input}
            className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
        {/* Taxa de Condomínio */}
        <div>
          <Label htmlFor="condominiumFee" className="text-gray-600">
            Taxa de Condomínio
          </Label>
          <CurrencyInput
            id="condominiumFee"
            name="condominiumFee"
            placeholder="Taxa de Condomínio"
            value={data.condominiumFee}
            decimalsLimit={2}
            decimalSeparator=","
            groupSeparator="."
            prefix="R$ "
            onValueChange={(value, name) => {
              onChange({
                target: {
                  name: name || '',
                  value: value || '',
                },
              } as React.ChangeEvent<HTMLInputElement>)
            }}
            customInput={Input}
            className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
        {/* IPTU */}
        <div>
          <Label htmlFor="iptu" className="text-gray-600">
            IPTU
          </Label>
          <CurrencyInput
            id="iptu"
            name="iptu"
            placeholder="IPTU"
            value={data.iptu}
            decimalsLimit={2}
            decimalSeparator=","
            groupSeparator="."
            prefix="R$ "
            onValueChange={(value, name) => {
              onChange({
                target: {
                  name: name || '',
                  value: value || '',
                },
              } as React.ChangeEvent<HTMLInputElement>)
            }}
            customInput={Input}
            className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
        {/* Método de Pagamento */}
        <div>
          <Label htmlFor="paymentMethod" className="text-gray-600">
            Condição Comercial
          </Label>
          <Input
            type="text"
            id="paymentMethod"
            name="paymentMethod"
            placeholder="Condição Comercial"
            value={data.paymentMethod || ''}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
      </div>

      {/* Observações */}
      <div className="mt-6 grid grid-cols-1 gap-4 md:grid-cols-3">
        {/* Observação Externa */}
        <div className="col-span-1 md:col-span-3">
          <Label htmlFor="externalObservation" className="text-gray-600">
            Observação Externa
          </Label>
          <textarea
            id="externalObservation"
            name="externalObservation"
            placeholder="Observação Externa"
            value={data.externalObservation || ''}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border border-gray-300 bg-white p-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            rows={3}
          ></textarea>
        </div>
        {/* Observação Interna */}
        <div className="col-span-1 md:col-span-3">
          <Label htmlFor="internalObservation" className="text-gray-600">
            Observação Interna
          </Label>
          <textarea
            id="internalObservation"
            name="internalObservation"
            placeholder="Observação Interna"
            value={data.internalObservation || ''}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border border-gray-300 bg-white p-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            rows={3}
          ></textarea>
        </div>
      </div>
    </div>
  )
}
