import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import type { Attribute } from '@/components/form-property/property-types'

type PropertyCardProps = {
  id: number
  title: string
  description: string
  images: string[]
  price?: string
  status: string
  condominiumPrice?: string
  iptuPrice?: string
  attributes: Attribute[]
  additionalInfo: string
}

export const PropertyCard = ({
  id,
  title,
  description,
  images,
  price = 'R$ 0,00',
  condominiumPrice = 'N/A',
  iptuPrice = 'N/A',
  attributes = [],
  additionalInfo,
  status,
}: PropertyCardProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const userCollaborator = localStorage.getItem('userCollaborator')
  const isAdmin = userCollaborator === 'ADMIN'

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1,
    )
  }

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    )
  }

  const handleDelete = async () => {
    const confirmDelete = confirm(
      'Tem certeza que deseja excluir esta propriedade?',
    )
    if (!confirmDelete) return

    try {
      const route = import.meta.env.VITE_URL_ENDPOINT
      const authToken = localStorage.getItem('authToken')
      if (!authToken) {
        alert('Usuário não autenticado.')
        return
      }

      const response = await fetch(`${route}/api/properties/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })

      if (!response.ok) {
        throw new Error('Falha ao excluir a propriedade.')
      }

      alert('Imóvel excluída com sucesso!')
      window.location.reload()
    } catch (error) {
      console.error('Erro ao excluir o Imóvel:', error)
      alert('Erro ao excluir o Imóvel.')
    }
  }

  return (
    <div className="mt-6 flex w-full justify-center">
      <div className="relative flex w-full max-w-4xl flex-col overflow-hidden rounded-md border bg-white shadow-lg sm:flex-row">
        {/* Seção da Imagem */}
        <div className="relative h-56 w-full sm:h-auto sm:w-1/2">
          {images.length > 0 ? (
            <img
              className="h-full w-full object-cover"
              src={images[currentImageIndex]}
              alt={title}
            />
          ) : (
            <div className="flex h-full w-full items-center justify-center bg-gray-200">
              Sem imagem
            </div>
          )}
          {images.length > 1 && (
            <>
              <button
                onClick={handlePrevImage}
                className="absolute left-2 top-1/2 -translate-y-1/2 transform rounded-full bg-white bg-opacity-50 p-1 text-sm font-bold text-black shadow sm:text-base"
              >
                &#10094;
              </button>
              <button
                onClick={handleNextImage}
                className="absolute right-2 top-1/2 -translate-y-1/2 transform rounded-full bg-white bg-opacity-50 p-1 text-sm font-bold text-black shadow sm:text-base"
              >
                &#10095;
              </button>
            </>
          )}

          {/* Preço e Condomínio em destaque na imagem */}
          <div className="absolute bottom-0 left-0 bg-black bg-opacity-60 p-2 text-white sm:p-3">
            <p className="text-sm font-bold sm:text-base">{price}</p>
            {condominiumPrice !== 'N/A' && (
              <p className="text-xs sm:text-sm">
                Condomínio {condominiumPrice}
              </p>
            )}
            {iptuPrice !== 'N/A' && (
              <p className="text-xs sm:text-sm">IPTU {iptuPrice}</p>
            )}
          </div>
        </div>

        {/* Seção de Informações */}
        <div className="flex w-full flex-col justify-between p-4 sm:w-1/2 sm:p-6">
          {/* Título e Descrição */}
          <div>
            <h2 className="mb-2 text-base font-bold text-gray-800 sm:text-lg">
              {title}
            </h2>
            <p className="text-sm text-gray-600 sm:text-base">
              {description} -{' '}
              <b className="font-semibold text-gray-800">{status}</b>
            </p>
            {additionalInfo && (
              <p className="mt-2 text-xs text-gray-500 sm:text-sm">
                {additionalInfo}
              </p>
            )}
          </div>

          {/* Atributos (vagas, quartos etc.) */}
          <div className="mt-3 flex flex-wrap gap-4">
            {attributes.map((attr, index) => (
              <div key={index} className="text-left">
                <p className="text-sm font-semibold text-gray-800 sm:text-base">
                  {attr.value}
                </p>
                <p className="text-xs text-gray-500 sm:text-sm">{attr.label}</p>
              </div>
            ))}
          </div>

          {/* Botões (Detalhes / Excluir) */}
          <div className="mt-4 flex items-center justify-between">
            <Link
              to={`/propriedade/${id}`}
              className="rounded-md bg-green-700 px-4 py-2 text-xs text-white hover:bg-green-800 sm:text-sm"
            >
              Acessar Imóvel
            </Link>
            {isAdmin && (
              <button
                onClick={handleDelete}
                className="text-gray-600 hover:text-red-700"
                title="Excluir Imóvel"
              >
                <FontAwesomeIcon icon={faTrash} className="sm:text-base" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
