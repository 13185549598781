// PhotoUpload.tsx

import { useState } from 'react'

import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'

interface PhotoUploadProps {
  selectedFiles: File[]
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>
}

export function PhotoUpload({
  selectedFiles,
  setSelectedFiles,
}: PhotoUploadProps) {
  const [errorPhotos, setErrorPhotos] = useState('')

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    const filesArray = Array.from(e.target.files)
    const existingCount = selectedFiles.length
    const totalCount = existingCount + filesArray.length

    if (totalCount > 25) {
      setErrorPhotos('Limite de 10 imagens excedido.')
      return
    }

    setErrorPhotos('')
    const limitedFiles = [...selectedFiles, ...filesArray].slice(0, 10)
    setSelectedFiles(limitedFiles)
  }

  return (
    <div className="mt-6">
      <Label htmlFor="photos" className="text-gray-600">
        Fotos (máximo 25)
      </Label>
      <Input
        type="file"
        id="photos"
        name="photos"
        multiple
        accept="image/*"
        onChange={handleFileChange}
        className="mt-1 block w-full"
      />
      {errorPhotos && (
        <p className="mt-1 text-sm text-red-500">{errorPhotos}</p>
      )}
      {selectedFiles.length > 0 && (
        <div className="mt-2">
          <p>Arquivos selecionados:</p>
          <div className="mt-2 flex flex-wrap gap-2">
            {selectedFiles.map((file, index) => (
              <div
                key={`${file.name}-${index}`}
                className="relative h-24 w-24 overflow-hidden"
              >
                <img
                  src={URL.createObjectURL(file)}
                  alt="preview"
                  className="h-full w-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
