import * as Dialog from '@radix-ui/react-dialog'
import * as Toast from '@radix-ui/react-toast'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BackgroundSign from '@/assets/backgroundSign.png'
import Logo from '@/assets/logo.png'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'

export function SignIn() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [toastOpen, setToastOpen] = useState(false)
  const [toastType, setToastType] = useState<'success' | 'error'>('success')
  const [toastMessage, setToastMessage] = useState('')
  const [resetEmail, setResetEmail] = useState('')
  const [resetToken, setResetToken] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [resetDialogOpen, setResetDialogOpen] = useState(false)
  const route = 'https://sistemaigt.com.br/api'

  async function handleSignIn() {
    try {
      const response = await fetch(`${route}/api/auth/local`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          identifier: email,
          password,
        }),
      })

      if (!response.ok) {
        throw new Error('Falha na autenticação')
      }

      const data = await response.json()
      localStorage.setItem('authToken', data.jwt)
      // Supondo que o usuário retornado possui o campo "collaborator" com valores "ADMIN" ou "BROKER"
      if (data.user && data.user.username) {
        localStorage.setItem('userName', data.user.username)
      }
      // Supondo que o usuário retornado possui o campo "collaborator" com valores "ADMIN" ou "BROKER"
      if (data.user && data.user.collaborator) {
        localStorage.setItem('userCollaborator', data.user.collaborator)
      }

      setToastType('success')
      setToastMessage('Login realizado com sucesso!')
      setToastOpen(true)
      navigate('/home')
    } catch (error) {
      console.error('Erro na autenticação:', error)
      setToastType('error')
      setToastMessage('Erro ao fazer login. Verifique suas credenciais.')
      setToastOpen(true)
    }
  }

  async function handleResetPassword() {
    try {
      const response = await fetch(`${route}/api/auth/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: resetEmail }),
      })

      if (!response.ok) {
        throw new Error('Erro ao enviar o email de redefinição')
      }

      setToastType('success')
      setToastMessage('Email de redefinição de senha enviado com sucesso!')
      setToastOpen(true)
      setDialogOpen(false)
      setResetDialogOpen(true)
    } catch (error) {
      console.error('Erro ao resetar a senha:', error)
      setToastType('error')
      setToastMessage('Erro ao enviar o email de redefinição.')
      setToastOpen(true)
    }
  }

  async function handleResetWithToken() {
    try {
      const response = await fetch(`${route}/api/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code: resetToken,
          password: newPassword,
          passwordConfirmation: confirmPassword,
        }),
      })

      if (!response.ok) {
        throw new Error('Erro na redefinição da senha')
      }

      setToastType('success')
      setToastMessage('Senha redefinida com sucesso!')
      setToastOpen(true)
      setResetDialogOpen(false)
    } catch (error) {
      console.error('Erro na redefinição de senha:', error)
      setToastType('error')
      setToastMessage('Erro ao redefinir a senha. Verifique o token.')
      setToastOpen(true)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSignIn()
    }
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-gray-200">
      <div className="flex h-full w-full">
        <div
          className="hidden bg-cover bg-center lg:flex lg:w-2/3"
          style={{ backgroundImage: `url(${BackgroundSign})` }}
        />

        <div className="flex h-full w-full flex-1 flex-col justify-center bg-white p-8 lg:w-1/3">
          <div className="mb-6 flex items-center justify-center">
            <img src={Logo} width={220} alt="Logo" className="text-center" />
          </div>

          <Label htmlFor="email" className="mb-2">
            Email
          </Label>
          <Input
            placeholder="Digite o seu email"
            className="mb-4"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Label htmlFor="password" className="mb-2">
            Senha
          </Label>
          <Input
            placeholder="**************"
            className=""
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
          />

          <div className="text-left">
            <button
              onClick={() => setDialogOpen(true)}
              className="mb-7 text-xs text-green-800 hover:text-green-700"
            >
              Esqueci minha senha
            </button>
          </div>

          <Button
            onClick={handleSignIn}
            className="w-full bg-green-900 hover:bg-green-800"
          >
            Acessar
          </Button>
        </div>
      </div>

      {/* Diálogo para envio do email de redefinição */}
      <Dialog.Root open={dialogOpen} onOpenChange={setDialogOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          <Dialog.Content className="fixed inset-1/4 rounded-lg bg-white p-6 shadow-lg">
            <Dialog.Title className="text-lg font-semibold">
              Redefinir Senha
            </Dialog.Title>
            <Dialog.Description className="mt-2 text-gray-600">
              Insira seu email para receber um token de redefinição de senha.
            </Dialog.Description>
            <div className="mt-4">
              <Label htmlFor="reset-email" className="mb-2">
                Email
              </Label>
              <Input
                id="reset-email"
                placeholder="Digite o seu email"
                className="mb-4"
                type="email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
              <div className="flex justify-end">
                <Button
                  onClick={handleResetPassword}
                  className="bg-blue-600 hover:bg-blue-500"
                >
                  Enviar
                </Button>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      {/* Diálogo para inserção do token e redefinição da senha */}
      <Dialog.Root open={resetDialogOpen} onOpenChange={setResetDialogOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          <Dialog.Content className="fixed inset-1/4 rounded-lg bg-white p-6 shadow-lg">
            <Dialog.Title className="text-lg font-semibold">
              Redefinir Senha com Token
            </Dialog.Title>
            <Dialog.Description className="mt-2 text-gray-600">
              Insira o token que recebeu por email, sua nova senha e a
              confirmação.
            </Dialog.Description>
            <div className="mt-4">
              <Label htmlFor="reset-token" className="mb-2">
                Token
              </Label>
              <Input
                id="reset-token"
                placeholder="Digite o token"
                className="mb-4"
                type="text"
                value={resetToken}
                onChange={(e) => setResetToken(e.target.value)}
              />
              <Label htmlFor="new-password" className="mb-2">
                Nova Senha
              </Label>
              <Input
                id="new-password"
                placeholder="Nova senha"
                className="mb-4"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <Label htmlFor="confirm-password" className="mb-2">
                Confirmar Nova Senha
              </Label>
              <Input
                id="confirm-password"
                placeholder="Confirme a nova senha"
                className="mb-4"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div className="flex justify-end">
                <Button
                  onClick={handleResetWithToken}
                  className="bg-blue-600 hover:bg-blue-500"
                >
                  Redefinir Senha
                </Button>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <Toast.Provider swipeDirection="right">
        <Toast.Root
          open={toastOpen}
          onOpenChange={setToastOpen}
          className={`fixed bottom-0 right-0 m-6 rounded-md p-4 shadow-md ${
            toastType === 'success'
              ? 'bg-green-500 text-white'
              : 'bg-red-500 text-white'
          }`}
        >
          <Toast.Description>{toastMessage}</Toast.Description>
          <Toast.Close className="absolute right-1 top-1" />
        </Toast.Root>
        <Toast.Viewport />
      </Toast.Provider>
    </div>
  )
}
