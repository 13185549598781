// Commercial.tsx

import React from 'react'

import { CommercialPropertyData } from '@/components/form-property/property-types'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

interface ComercialProps {
  data: CommercialPropertyData
  onChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => void
  onCheckboxChange: (
    name: keyof CommercialPropertyData,
    checked: boolean | 'indeterminate',
  ) => void
}

export function Comercial({
  data,
  onChange,
  onCheckboxChange,
}: ComercialProps) {
  const handleSelectChange = (
    field: keyof CommercialPropertyData,
    value: string,
  ) => {
    const event = {
      target: {
        name: field,
        value,
      },
    } as React.ChangeEvent<HTMLInputElement>
    onChange(event)
  }

  return (
    <div className="flex h-full w-full flex-col bg-gray-100 p-8">
      <h1 className="mb-6 text-center text-3xl font-bold text-gray-800">
        Detalhes do Imóvel Comercial
      </h1>

      <div className="rounded-lg bg-white p-6 shadow-md">
        <div>
          <Label htmlFor="type" className="text-gray-600">
            Tipo
          </Label>
          <Select
            value={data.type || ''}
            onValueChange={(value: string) => handleSelectChange('type', value)}
          >
            <SelectTrigger
              id="type"
              name="type"
              className="mb-4 mt-1 w-1/4 rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <SelectValue placeholder="Tipo" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Pousada">Pousada</SelectItem>
              <SelectItem value="Conjunto">Conjunto</SelectItem>
              <SelectItem value="Loja">Loja</SelectItem>
              <SelectItem value="Prédio">Prédio</SelectItem>
              <SelectItem value="Sala">Sala</SelectItem>
              <SelectItem value="Galpão">Galpão</SelectItem>
              <SelectItem value="Hotel">Hotel</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <div>
            <Label htmlFor="cabinets" className="text-gray-600">
              Armários
            </Label>
            <Input
              type="text"
              id="cabinets"
              name="cabinets"
              placeholder="Número de Armários"
              value={data.cabinets}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <Label htmlFor="bathrooms" className="text-gray-600">
              Banheiros
            </Label>
            <Input
              type="text"
              id="bathrooms"
              name="bathrooms"
              placeholder="Número de Banheiros"
              value={data.bathrooms}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <Label htmlFor="conservation" className="text-gray-600">
              Conservação
            </Label>
            <Input
              type="text"
              id="conservation"
              name="conservation"
              placeholder="Estado de Conservação"
              value={data.conservation}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <Label htmlFor="guardhouse" className="text-gray-600">
              Guarita
            </Label>
            <Input
              type="text"
              id="guardhouse"
              name="guardhouse"
              placeholder="Guarita"
              value={data.guardhouse}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <Label htmlFor="numberOfRooms" className="text-gray-600">
              Número de Salas
            </Label>
            <Input
              type="text"
              id="numberOfRooms"
              name="numberOfRooms"
              placeholder="Número de Salas"
              value={data.numberOfRooms}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <Label htmlFor="kitchen" className="text-gray-600">
              Cozinha
            </Label>
            <Input
              type="text"
              id="kitchen"
              name="kitchen"
              placeholder="Cozinha"
              value={data.kitchen}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <Label htmlFor="copa" className="text-gray-600">
              Copa
            </Label>
            <Input
              type="text"
              id="copa"
              name="copa"
              placeholder="Copa"
              value={data.copa}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <Label htmlFor="keys" className="text-gray-600">
              Chaves
            </Label>
            <Input
              type="text"
              id="keys"
              name="keys"
              placeholder="Chaves"
              value={data.keys}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>

        <h2 className="mb-4 mt-6 text-xl font-semibold text-gray-700">
          Comodidades
        </h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="intercom"
              name="intercom"
              checked={data.intercom}
              onCheckedChange={(checked) =>
                onCheckboxChange('intercom', checked)
              }
            />
            <Label htmlFor="intercom" className="text-gray-600">
              Interfone
            </Label>
          </div>
        </div>
      </div>
    </div>
  )
}
