// Leisure.tsx

import React, { useState } from 'react'

import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'

import { LeisureData } from './property-types'

interface LeisureProps {
  data: LeisureData
  onChange: (field: keyof LeisureData, value: unknown) => void
  onCheckboxChange: (
    name: keyof LeisureData,
    checked: boolean | 'indeterminate',
  ) => void
}

export function Leisure({ data, onChange, onCheckboxChange }: LeisureProps) {
  const [errorPhotos, setErrorPhotos] = useState('')

  // Lidamos com `uploadPhotos` (File[]) p/ upload local
  const handlePhotosChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    const filesToAdd = Array.from(e.target.files)
    const existingCount = data.uploadPhotos?.length || 0
    const totalCount = existingCount + filesToAdd.length

    if (totalCount > 20) {
      setErrorPhotos('Limite de 20 imagens excedido.')
      return
    }

    setErrorPhotos('')
    const newFiles = [...(data.uploadPhotos || []), ...filesToAdd]

    // Dispara para o pai, salvando em `uploadPhotos` e não em `photos`
    onChange('uploadPhotos', newFiles)
  }

  return (
    <div className="flex h-full w-full flex-col bg-gray-100 p-8">
      <h1 className="mb-6 text-center text-3xl font-bold text-gray-800">
        Detalhes de Lazer
      </h1>

      <div className="rounded-lg bg-white p-6 shadow-md">
        {/* --------------------------------------------------- */}
        {/* Checkboxes de lazer */}
        {/* --------------------------------------------------- */}

        {/* Piscinas */}
        <h2 className="mb-4 text-xl font-semibold text-gray-700">Piscinas</h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          {/* Piscina Adulto */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="adultPool"
              name="adultPool"
              checked={data.adultPool || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('adultPool', checked)
              }
            />
            <Label htmlFor="adultPool" className="text-gray-600">
              Piscina Adulto
            </Label>
          </div>

          {/* Piscina Infantil */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="kidsPool"
              name="kidsPool"
              checked={data.kidsPool || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('kidsPool', checked)
              }
            />
            <Label htmlFor="kidsPool" className="text-gray-600">
              Piscina Infantil
            </Label>
          </div>

          {/* Piscina com Deck Molhado */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="wetDeckPool"
              name="wetDeckPool"
              checked={data.wetDeckPool || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('wetDeckPool', checked)
              }
            />
            <Label htmlFor="wetDeckPool" className="text-gray-600">
              Piscina com Deck Molhado
            </Label>
          </div>

          {/* Piscina de Raia */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="lapPool"
              name="lapPool"
              checked={data.lapPool || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('lapPool', checked)
              }
            />
            <Label htmlFor="lapPool" className="text-gray-600">
              Piscina de Raia
            </Label>
          </div>

          {/* Piscina Infantil com Praia */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="kidsBeachPool"
              name="kidsBeachPool"
              checked={data.kidsBeachPool || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('kidsBeachPool', checked)
              }
            />
            <Label htmlFor="kidsBeachPool" className="text-gray-600">
              Piscina Infantil com Praia
            </Label>
          </div>

          {/* Solarium */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="solarium"
              name="solarium"
              checked={data.solarium || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('solarium', checked)
              }
            />
            <Label htmlFor="solarium" className="text-gray-600">
              Solarium
            </Label>
          </div>

          {/* Bar da Piscina */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="poolBar"
              name="poolBar"
              checked={data.poolBar || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('poolBar', checked)
              }
            />
            <Label htmlFor="poolBar" className="text-gray-600">
              Bar da Piscina
            </Label>
          </div>
        </div>

        {/* Instalações Esportivas */}
        <h2 className="mb-4 mt-8 text-xl font-semibold text-gray-700">
          Instalações Esportivas
        </h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          {/* Academia */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="gym"
              name="gym"
              checked={data.gym || false}
              onCheckedChange={(checked) => onCheckboxChange('gym', checked)}
            />
            <Label htmlFor="gym" className="text-gray-600">
              Academia
            </Label>
          </div>

          {/* Quadra Esportiva */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="sportsCourt"
              name="sportsCourt"
              checked={data.sportsCourt || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('sportsCourt', checked)
              }
            />
            <Label htmlFor="sportsCourt" className="text-gray-600">
              Quadra Esportiva
            </Label>
          </div>

          {/* Quadra de Tênis */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="tennisCourt"
              name="tennisCourt"
              checked={data.tennisCourt || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('tennisCourt', checked)
              }
            />
            <Label htmlFor="tennisCourt" className="text-gray-600">
              Quadra de Tênis
            </Label>
          </div>

          {/* Quadra de Squash */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="squashCourt"
              name="squashCourt"
              checked={data.squashCourt || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('squashCourt', checked)
              }
            />
            <Label htmlFor="squashCourt" className="text-gray-600">
              Quadra de Squash
            </Label>
          </div>

          {/* Espaço Pilates */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="pilatesSpace"
              name="pilatesSpace"
              checked={data.pilatesSpace || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('pilatesSpace', checked)
              }
            />
            <Label htmlFor="pilatesSpace" className="text-gray-600">
              Espaço Pilates
            </Label>
          </div>

          {/* Sala de Massagem */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="massageRoom"
              name="massageRoom"
              checked={data.massageRoom || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('massageRoom', checked)
              }
            />
            <Label htmlFor="massageRoom" className="text-gray-600">
              Sala de Massagem
            </Label>
          </div>

          {/* Sauna */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="sauna"
              name="sauna"
              checked={data.sauna || false}
              onCheckedChange={(checked) => onCheckboxChange('sauna', checked)}
            />
            <Label htmlFor="sauna" className="text-gray-600">
              Sauna
            </Label>
          </div>
        </div>

        {/* Salas de Lazer */}
        <h2 className="mb-4 mt-8 text-xl font-semibold text-gray-700">
          Salas de Lazer
        </h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          {/* Salão de Festas */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="partyHall"
              name="partyHall"
              checked={data.partyHall || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('partyHall', checked)
              }
            />
            <Label htmlFor="partyHall" className="text-gray-600">
              Salão de Festas
            </Label>
          </div>

          {/* Salão Social */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="socialHall"
              name="socialHall"
              checked={data.socialHall || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('socialHall', checked)
              }
            />
            <Label htmlFor="socialHall" className="text-gray-600">
              Salão Social
            </Label>
          </div>

          {/* Sala de Jogos */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="gameRoom"
              name="gameRoom"
              checked={data.gameRoom || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('gameRoom', checked)
              }
            />
            <Label htmlFor="gameRoom" className="text-gray-600">
              Sala de Jogos
            </Label>
          </div>

          {/* Sala de Jogos Juvenil */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="youthGameRoom"
              name="youthGameRoom"
              checked={data.youthGameRoom || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('youthGameRoom', checked)
              }
            />
            <Label htmlFor="youthGameRoom" className="text-gray-600">
              Sala de Jogos Juvenil
            </Label>
          </div>

          {/* Sala de Jogos Adulto */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="adultGameRoom"
              name="adultGameRoom"
              checked={data.adultGameRoom || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('adultGameRoom', checked)
              }
            />
            <Label htmlFor="adultGameRoom" className="text-gray-600">
              Sala de Jogos Adulto
            </Label>
          </div>

          {/* Brinquedoteca */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="toyLibrary"
              name="toyLibrary"
              checked={data.toyLibrary || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('toyLibrary', checked)
              }
            />
            <Label htmlFor="toyLibrary" className="text-gray-600">
              Brinquedoteca
            </Label>
          </div>

          {/* Cinema */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="cinemaRoom"
              name="cinemaRoom"
              checked={data.cinemaRoom || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('cinemaRoom', checked)
              }
            />
            <Label htmlFor="cinemaRoom" className="text-gray-600">
              Cinema
            </Label>
          </div>

          {/* Sala de Leitura */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="readingRoom"
              name="readingRoom"
              checked={data.readingRoom || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('readingRoom', checked)
              }
            />
            <Label htmlFor="readingRoom" className="text-gray-600">
              Sala de Leitura
            </Label>
          </div>

          {/* Lounge */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="lounge"
              name="lounge"
              checked={data.lounge || false}
              onCheckedChange={(checked) => onCheckboxChange('lounge', checked)}
            />
            <Label htmlFor="lounge" className="text-gray-600">
              Lounge
            </Label>
          </div>

          {/* Sala de TV Adulto */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="adultTVRoom"
              name="adultTVRoom"
              checked={data.adultTVRoom || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('adultTVRoom', checked)
              }
            />
            <Label htmlFor="adultTVRoom" className="text-gray-600">
              Sala de TV Adulto
            </Label>
          </div>

          {/* Espaço Coworking */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="coworkingSpace"
              name="coworkingSpace"
              checked={data.coworkingSpace || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('coworkingSpace', checked)
              }
            />
            <Label htmlFor="coworkingSpace" className="text-gray-600">
              Espaço Coworking
            </Label>
          </div>
        </div>

        {/* Áreas Externas */}
        <h2 className="mb-4 mt-8 text-xl font-semibold text-gray-700">
          Áreas Externas
        </h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          {/* Playground */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="playground"
              name="playground"
              checked={data.playground || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('playground', checked)
              }
            />
            <Label htmlFor="playground" className="text-gray-600">
              Playground
            </Label>
          </div>

          {/* Área de Descanso com Redário */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="hammockArea"
              name="hammockArea"
              checked={data.hammockArea || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('hammockArea', checked)
              }
            />
            <Label htmlFor="hammockArea" className="text-gray-600">
              Área de Descanso com Redário
            </Label>
          </div>

          {/* Espaço Zen */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="zenSpace"
              name="zenSpace"
              checked={data.zenSpace || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('zenSpace', checked)
              }
            />
            <Label htmlFor="zenSpace" className="text-gray-600">
              Espaço Zen
            </Label>
          </div>

          {/* Pet Place */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="petPlace"
              name="petPlace"
              checked={data.petPlace || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('petPlace', checked)
              }
            />
            <Label htmlFor="petPlace" className="text-gray-600">
              Pet Place
            </Label>
          </div>

          {/* Área de Churrasco */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="bbqArea"
              name="bbqArea"
              checked={data.bbqArea || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('bbqArea', checked)
              }
            />
            <Label htmlFor="bbqArea" className="text-gray-600">
              Área de Churrasco
            </Label>
          </div>
        </div>

        {/* Salões de Festa */}
        <h2 className="mb-4 mt-8 text-xl font-semibold text-gray-700">
          Salões de Festa
        </h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          {/* Espaço Gourmet */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="gourmetSpace"
              name="gourmetSpace"
              checked={data.gourmetSpace || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('gourmetSpace', checked)
              }
            />
            <Label htmlFor="gourmetSpace" className="text-gray-600">
              Espaço Gourmet
            </Label>
          </div>

          {/* Espaço Gourmet com Bar */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="gourmetBar"
              name="gourmetBar"
              checked={data.gourmetBar || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('gourmetBar', checked)
              }
            />
            <Label htmlFor="gourmetBar" className="text-gray-600">
              Espaço Gourmet com Bar
            </Label>
          </div>

          {/* Salão de Festas Infantil */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="kidsPartyRoom"
              name="kidsPartyRoom"
              checked={data.kidsPartyRoom || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('kidsPartyRoom', checked)
              }
            />
            <Label htmlFor="kidsPartyRoom" className="text-gray-600">
              Salão de Festas Infantil
            </Label>
          </div>

          {/* Salão de Festas Adulto com Bar */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="adultPartyRoomWithBar"
              name="adultPartyRoomWithBar"
              checked={data.adultPartyRoomWithBar || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('adultPartyRoomWithBar', checked)
              }
            />
            <Label htmlFor="adultPartyRoomWithBar" className="text-gray-600">
              Salão de Festas Adulto com Bar
            </Label>
          </div>
        </div>

        {/* Outras Instalações */}
        <h2 className="mb-4 mt-8 text-xl font-semibold text-gray-700">
          Outras Instalações
        </h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          {/* Espaço Mulher */}
          <div className="flex items-center space-x-2">
            <Checkbox
              id="womenSpace"
              name="womenSpace"
              checked={data.womenSpace || false}
              onCheckedChange={(checked) =>
                onCheckboxChange('womenSpace', checked)
              }
            />
            <Label htmlFor="womenSpace" className="text-gray-600">
              Espaço Mulher
            </Label>
          </div>
        </div>
        <Label
          htmlFor="leisure-photos"
          className="mb-2 mt-6 block text-lg font-medium text-gray-700"
        >
          Fotos de Lazer (máx 20)
        </Label>
        <input
          type="file"
          id="leisure-photos"
          accept="image/*"
          multiple
          onChange={handlePhotosChange}
          className="mb-2"
        />
        {errorPhotos && (
          <p className="mt-1 text-sm text-red-500">{errorPhotos}</p>
        )}

        {/* Pré-visualização das imagens (se data.photos for File[]) */}
        {data.uploadPhotos && data.uploadPhotos.length > 0 && (
          <div className="mt-4 flex flex-wrap gap-2">
            {data.uploadPhotos.map((file, idx) => {
              if (file instanceof File) {
                return (
                  <div key={idx} className="relative h-24 w-24 overflow-hidden">
                    <img
                      src={URL.createObjectURL(file)}
                      alt="preview"
                      className="h-full w-full object-cover"
                    />
                  </div>
                )
              }
              // Caso seja string (URL de imagem), poderia mostrar <img src={file} />
              return null
            })}
          </div>
        )}
      </div>
    </div>
  )
}
